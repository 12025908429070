// "Production" enabled environment

export const environment = {
    production: true,
    hmr: false,
    appConfig: 'appconfig.staging.json',
    analytics:{
      baseUrl: 'https://opex-uat-analytics-api.azurewebsites.net/',
      applicationId: '00000000-0000-0000-0000-000000000001',
      apiKey: '67987338c1b04ec8814e3ccbe1735c8e-48ff62e3c5a34f47880db6c0772543ec'
    },
    raygunKey: 'InFt3OYFYSYTfK3XkZBog'
};
